import React from 'react'
import Navigation from '../components/navigation/navigation'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Navigation>
    <SEO title="404: Not found" />
    <div className="container">
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </Navigation>
)

export default NotFoundPage
